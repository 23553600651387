<template>
    <div class="not_found"></div>
</template>

<script>
export default {
    data() {
        return {};
    }
};
</script>


<style lang="scss" scoped>
.not_found {
    width: 100%;
    height: 100%;
    background: url(../../assets/images/404.png) no-repeat;
    background-size: 100%;
}
</style>